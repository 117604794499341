import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ResetPasswordModal from './ResetPasswordModal';

const AuthModal = ({ show, handleClose, authMode, handleAuthChange, handleAuthSubmit, authFormData }) => {
  const [showResetModal, setShowResetModal] = useState(false);

  const handleOpenResetModal = () => {
    setShowResetModal(true);
  };

  const handleCloseResetModal = () => {
    setShowResetModal(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{authMode === 'signIn' ? 'Sign In' : 'Sign Up'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAuthSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label><strong>Email Address</strong></Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={authFormData.email || ''}
                onChange={handleAuthChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPassword">
              <Form.Label><strong>Password</strong></Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={authFormData.password || ''}
                onChange={handleAuthChange}
                required
              />
            </Form.Group>

            {authMode === 'signUp' && (
              <Form.Group controlId="formConfirmPassword">
                <Form.Label><strong>Confirm Password</strong></Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  value={authFormData.confirmPassword || ''}
                  onChange={handleAuthChange}
                  required
                />
              </Form.Group>
            )}

            <Button variant="primary" className="mt-3" type="submit">
              {authMode === 'signIn' ? 'Sign In' : 'Sign Up'}
            </Button>

            {/* Forgot Password Link */}
            {authMode === 'signIn' && (
              <p className="mt-3">
                <Button variant="link" onClick={handleOpenResetModal}>
                  Forgot Password?
                </Button>
              </p>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Reset Password Modal */}
      <ResetPasswordModal show={showResetModal} handleClose={handleCloseResetModal} />
    </>
  );
};

export default AuthModal;
